<template>
  <div class="mt-3">
    <div :class="{ 'pt-5': !hasFooter }" class="px-2 px-lg-5">
      <div v-if="hasFooter" class="pt-3 px-lg-5">
        <span
          v-if="canEditFooter" 
          class="d-flex"
        >
          <h4 class="font-weight-bolder mr-50">
            {{ $t('footer.label') }}
          </h4>
          <feather-icon
            icon="Edit2Icon"
            size="20"
            class="text-primary edit-icon icon-button"
            @click="showEditFooterModal()"
          />
        </span>
        <FooterEdition
          v-if="isEditingFooter"
          :content="footerEdited || footerEdited === '' ? footerEdited : translatedDescription"
          @footer-changed="handleChangedFooter"
        />
        <p v-if="showFooterContent" class="px-lg-5">
          <ContentWithAnchors
            class="footer-description"
            tag="p"
            :content="footerEdited || footerEdited === '' ? footerEdited : translatedDescription"
          />
        </p>
      </div>
      <div v-if="showPoweredBy" class="footer-copyright text-center" :class="hasFooter ? 'py-1' : 'py-3'">
        <!-- Powered by Nectios -->
        <b-link 
          v-if="isCommunity" 
          :href="landingURL" 
          target="_blank"
        >
          <b-img
            :src="brand"
            alt="Powered by Nectios"
            class="brand mb-1"
          />
        </b-link>
        <!-- My Nectios -->
        <div v-else>
          <b-link href="https://nectios.com/" target="_blank">
            <b-img
              :src="nectiosLogo"
              alt="Nectios"
              class="brand mb-1"
            />
          </b-link>
          <div class="legat-texts-links">
            <small>
              <b-link href="https://nectios.com/es/privacy-policy/" target="_blank">
                {{ $t('header.action.privacy-policy') }}
              </b-link>
                | 
              <b-link href="https://nectios.com/wpautoterms/terms-and-conditions/" target="_blank">
                {{ $t('about-nectios.terms-conditions') }}
              </b-link>
            </small>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { translateTranslationTable } from '@/@core/libs/i18n/utils';
import GridStatusMixin from '@core/mixins/apps-layout/GridStatusMixin';
import { ENABLED_APPS_GETTERS } from '@/store/enabled-apps/enabled-apps-store-constants';
import BrandLogo from '@/assets/images/brand/powerby-nectios.svg';
import NectiosLogo from '@/assets/images/brand/brand-logo.svg';
// import ContentWithAnchors from '@core/components/string-utils/ContentWithAnchors.vue';
// import FooterEdition from './FooterEdition.vue';

export default {
  name: 'AppFooter',
  components: {
    ContentWithAnchors: () => import('@core/components/string-utils/ContentWithAnchors.vue' /* webpackChunkName: "ContentWithAnchors" */),
    FooterEdition: () => import('./FooterEdition.vue' /* webpackChunkName: "FooterEdition" */),
  },
  mixins: [GridStatusMixin],
  data() {
    return {
      footerEdited: null,
      isEditingFooter: false,
    };
  },
  computed: {
    currentCollective() {
      return this.$store.getters.currentCollective;
    },
    mainCollective() {
      return this.$store.getters.mainCollective;
    },
    mainSpace() {
      return this.mainCollective.key ? this.mainCollective : this.currentCollective;
    },
    translatedDescription() {
      return translateTranslationTable(this.$store.state.locale.currentLocale, this.currentCollective?.footer?.body || this.mainCollective.footer?.body);
    },
    hasFooter() {
      return true;
    },
    isAdmin() {
      return this.$store.getters.loggedMember?.isStaff;
    },
    canEditLayout() {
      return this.isAdmin && !this.isGridDisabled;
    },
    canEditFooter() {
      return this.canEditLayout && this.isEditingLayout;
    },
    isEditingLayout() {
      return this.$store.getters[ENABLED_APPS_GETTERS.isEditingLayout];
    },
    showFooterContent() {
      return (this.currentCollective.footer || this.footerEdited != null) && !this.$route.path.includes('backoffice');
    },
    landingURL() {
      if (process.env.VUE_APP_LANDING_URL_FROM != null) {
        return process.env.VUE_APP_LANDING_URL_FROM
          .replace('{from}', 'community_footer')
          .replace('{slug}', this.$store.getters.currentCollective?.slug);
      }
      return null;
    },
    brand() {
      return BrandLogo;
    },
    nectiosLogo() {
      return NectiosLogo;
    },
    isCommunity() {
      return this.$route?.params?.communityId || this.$route?.params?.communitySlug;
    },
    showPoweredBy() {
      return !this.mainSpace || this.mainSpace.level === null || this.mainSpace.level > 1;
    }
  },
  watch: {
    footerEdited(value) {
      return value;
    },
  },
  methods: {
    handleChangedFooter(content) {
      this.footerEdited = content;
      const body = content;
      this.$store.dispatch('editFooter', { body });
      this.isEditingFooter = false;
      this.$bvModal.hide('modal-edit-footer');
    },
    showEditFooterModal() {
      this.isEditingFooter = true;
      setTimeout(() => {
        this.$bvModal.show('modal-edit-footer');
      }, 1000);
      //this.$bvModal.show('modal-edit-footer');
    }
  },
};
</script>

<style lang="scss" scoped>
.footer-description {
  width: 100%;
  max-height: 500px;
  overflow: hidden;
}
.brand {
  height: 32px;
}
.icon-button {
  cursor: pointer;
}
</style>
